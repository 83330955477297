import React from 'react';
import { useTranslation } from 'react-i18next';
import RestoreOutlinedIcon from '@material-ui/icons/RestoreOutlined';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import { BasicSideBar } from '@izi-logistics/common/lib/components';
import { CompanyState } from '@izi-logistics/common/lib/stores/common/BaseCompany';
import { BasicSideBarMenuItem } from '@izi-logistics/common/lib/components';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import { KEYS } from '@izi-logistics/common/lib/i18n';
// import ChannelChatIcon from '@izi-logistics/common/lib/pages/Chat/ChannelChatIcon';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import { client } from '../../api/apolloClient';
import { inject } from 'mobx-react';
import { InProgressIcon } from '@izi-logistics/common/lib/components/icons';
// import { WarehouseIcon } from '@izi-logistics/common/lib/components/icons';

export default inject('store')(
  ({
    store: {
      accountStore: { currentCompany },
    },
  }: any) => {
    const langMenu: any = useTranslation(KEYS.MENU);
    const langApp: any = useTranslation(KEYS.APP);

    const restrictedOrdersAccess = currentCompany.state.includes(CompanyState.ORDER_ACCESS_RESTRICTION);
    const menu: BasicSideBarMenuItem[] = [
      {
        icon: <WorkOutlineIcon />,
        title: langMenu.t('menu_sidebar_open_orders_title'),
        path: '/open-orders',
        _id: 'menu-sidebar-open-orders',
      },
      {
        icon: (
          <span>
            <InProgressIcon title={langMenu.t('menu_sidebar_in_progress_title')} />
          </span>
        ),
        title: langMenu.t('menu_sidebar_in_progress_title'),
        path: '/in-progress',
        _id: 'menu-sidebar-in-progress',
      },

      {
        icon: <RestoreOutlinedIcon />,
        title: langMenu.t('menu_sidebar_archive_title'),
        path: '/archive',
        _id: 'menu-sidebar-archive',
        access: !restrictedOrdersAccess,
      },
      {
        icon: <GroupOutlinedIcon />,
        title: langMenu.t('menu_sidebar_team_title'),
        path: '/team',
        _id: 'menu-sidebar-team',
        access: !restrictedOrdersAccess,
      },

      {
        icon: <AllInboxIcon />,
        title: langApp.t('main_finance'),
        path: '/finance',
        _id: 'menu-sidebar-finance',
        access: !restrictedOrdersAccess,
      },

      // {
      //   icon: (
      //     <span>
      //       <WarehouseIcon title={langMenu.t('menu_sidebar_warehouse')} />
      //     </span>
      //   ),
      //   title: langMenu.t('menu_sidebar_warehouse'),
      //   path: '/public/warehouses',
      //   _id: 'menu-sidebar-warehouses',
      // },
    ].filter((e) => e && e.access !== false);

    return <BasicSideBar menu={currentCompany?._id ? menu : []} config={{ client }} />;
  }
);
