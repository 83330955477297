import React from 'react';
import { IZIOrderListItem } from '@izi-logistics/common/lib/models';
import { withRouter } from 'react-router-dom';
import { BaseOrderQuoteItem } from '@izi-logistics/common/lib/components';
import { OrderViewProps } from '../../../../pages/Orders/types';
import { MainStoreProps } from '@izi-logistics/common/lib/models/entities';
import { inject } from 'mobx-react';
import { OrderQuoteDetails } from './Quotes/OrderQuoteDetails';
import { OrderStatus } from '@izi-logistics/common/lib/models';
import { withOrderListItemStore } from '@izi-logistics/common/lib/components/Order/List/Customer/storeListItem';

type OrderQuoteItemProps = {
  order: IZIOrderListItem;
  onChange: Function;
};

const OrderQuoteItem = inject('store')(
  withOrderListItemStore(
    ({
      orderListItemProps,
      onChange,
      config,
      ...props
    }: (OrderQuoteItemProps & OrderViewProps & MainStoreProps) | any) => {
      const order = orderListItemProps.state.record;
      const status: string = order.status;
      let additionalContentBody: React.Component | any;
      const _onChange = (...e) => onChange(...e, { refreshFilter: 1 });
      if ([OrderStatus.WaitingQuotes, OrderStatus.HasQuotes].includes(status)) {
        additionalContentBody = (orderDetails) => (
          <OrderQuoteDetails order={orderDetails} onChange={_onChange} record={order.latestQuote} />
        );
      }

      return (
        <>
          <BaseOrderQuoteItem
            additionalContentBodyV1={additionalContentBody}
            order={order}
            config={config}
            onChange={_onChange}
            expanded={props.expanded}
            setExpanded={props.setExpanded}
          />
        </>
      );
    }
  )
);
const component: OrderQuoteItemProps | any = withRouter(OrderQuoteItem);
export default component;
