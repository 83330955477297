import React from 'react';
import { client, URL } from '../../api/apolloClient';
import PreloadingComponent from '@izi-logistics/common/lib/components/PreloadingComponent';
import OrderQuoteItem from '../../components/Order/List/OrderQuoteItem';

const OrderList = React.lazy(() => import('@izi-logistics/common/lib/components/Order/List/Customer/List'));

export default function CarrierOrderList(props) {
  return (
    <PreloadingComponent>
      <OrderList
        {...props}
        config={{ URL, client }}
        expandContent={(p) => <OrderQuoteItem {...p} config={{ URL, client }} />}
      />
    </PreloadingComponent>
  );
}
