import React from 'react';
import PreloadingComponent from '@izi-logistics/common/lib/components/PreloadingComponent';
import { client } from '../../api/apolloClient';

const List = React.lazy(() => import('@izi-logistics/common/lib/pages/Issues'));

const Issues = () => {
  return (
    <PreloadingComponent>
      <List config={{ client }} />
    </PreloadingComponent>
  );
};
export default Issues;
