import React from 'react';
import { client } from '../../api/apolloClient';
import PreloadingComponent from '@izi-logistics/common/lib/components/PreloadingComponent';

const TeamPage = React.lazy(() => import('@izi-logistics/common/lib/pages/TeamPage'));

const Team = () => (
  <PreloadingComponent>
    <TeamPage client={client} />
  </PreloadingComponent>
);
export default Team;
