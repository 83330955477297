import React from 'react';
import { CompletePaperLayout } from '@izi-logistics/common/lib/components/CompletePaperLayout';
import { Redirect, Route, Switch } from 'react-router';
import OrderList from './pages/Orders/List';
import OpenOrderList from './pages/Orders/OpenOrderList';
import Account from './pages/Account/Account';
import Team from './pages/Team/Team';
import { CompanyState } from '@izi-logistics/common/lib/stores/common/BaseCompany';
import CarrierSidebarMenu from './components/common/CarrierSidebarMenu';
import { client } from './api/apolloClient';
import ChatContentComponent from './pages/Chat';
import InvoiceForm from './pages/Finance/Invoices/Form';
import CostForm from './pages/Finance/Costs/Form';
import Finance from './pages/Finance';
import { useTranslation } from 'react-i18next';
import { KEYS } from '@izi-logistics/common/lib/i18n';
import { BaseUserAppTYpe } from '@izi-logistics/common/lib/stores/common';

import PreloadingComponent from '@izi-logistics/common/lib/components/PreloadingComponent';

import Issues from './pages/Issues';
const SettingsVehicleType = React.lazy(() => import('./components/Settings/SettingsVehicleType'));

export default function RootRoutes({ store }: any) {
  const defaultPath = '/open-orders';
  const langForm = useTranslation(KEYS.FORM);
  const restrictedOrdersAccess = store.accountStore.currentCompany.state.includes(
    CompanyState.ORDER_ACCESS_RESTRICTION
  );

  const isNew = Date.now() < new Date('2021-10-20').getTime();
  const settings = {
    beforeTabs: [
      {
        label: langForm.t('form_input_name_order_preferences'),
        isNew,
        content: (open) =>
          (open && (
            <PreloadingComponent withoutLoader>
              <SettingsVehicleType />
            </PreloadingComponent>
          )) ||
          null,
      },
    ],
  };

  const generalRoutes = (
    <Switch>
      <Route exact path="/quotes/:quoteId/invoices/:invoiceId" component={InvoiceForm} />
      <Route exact path="/finance/invoices/create" component={() => <InvoiceForm key={'invoice-create'} />} />
      <Route
        exact
        path="/finance/invoices/:invoiceId/credit"
        component={() => <InvoiceForm key={'invoice-credit'} />}
      />
      <Route exact path="/finance/invoices/:invoiceId" component={() => <InvoiceForm key={'invoice-form'} />} />
      <Route
        exact
        path="/finance/invoices-templates/:invoiceId"
        component={() => <InvoiceForm key={'invoices-templates-form'} />}
      />
      <Route exact path="/finance/costs/create" component={() => <CostForm key={'cost-create'} />} />
      <Route
        exact
        path="/finance/costs/:invoiceId/request-refund"
        component={() => <CostForm key={'request-refund'} />}
      />
      <Route exact path="/finance/costs/:invoiceId" component={() => <CostForm key={'cost'} />} />
      <Route exact path="/finance/costs-templates/:invoiceId" component={() => <CostForm key={'costs-templates'} />} />
      <Route path="/finance" component={Finance} />
      <Route exact path="/archive" component={(p: any) => <OrderList {...p} key={p.match.url} />} />
      <Route exact path="/open-orders" component={(props: any) => <OpenOrderList {...props} key={props.match.url} />} />
      <Route exact path="/in-progress" component={(p: any) => <OrderList {...p} key={p.match.url} />} />
      <Route exact path="/issues" component={Issues} />
      <Route exact path="/account/filters" component={Account} />
      <Route exact path="/account/details" component={Account} />
      <Route exact path="/account/settings" component={Account} />
      <Route path="/account">
        <Redirect to={'/account/details'} />
      </Route>
      <Route exact path="/team" component={Team} />
      <Route path="/chats" component={ChatContentComponent} />
      <Route path="*">
        <Redirect to={defaultPath} />
      </Route>
    </Switch>
  );
  let routes =
    ![BaseUserAppTYpe.CARRIER].includes(store.accountStore.currentCompany.type) &&
    (!store.accountStore.currentCompany.vatCode || store.accountStore.currentCompany.joinRequest) ? (
      <Switch>
        <Route path="/account" component={Account} />
        <Route path="*">
          <Redirect to={'/account/details'} />
        </Route>
      </Switch>
    ) : (
      generalRoutes
    );
  if (restrictedOrdersAccess) {
    routes = generalRoutes;
  }

  return (
    <CompletePaperLayout
      settings={settings}
      appType={BaseUserAppTYpe.CARRIER}
      config={{ client }}
      sideBarMenuContent={<CarrierSidebarMenu />}
      pageContent={routes}
    />
  );
}
