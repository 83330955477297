import React from 'react';
import { client } from '../../api/apolloClient';

import PreloadingComponent from '@izi-logistics/common/lib/components/PreloadingComponent';
import { i18n, KEYS } from '@izi-logistics/common/lib/i18n';
import { inject } from 'mobx-react';
const AccountPage = React.lazy(() => import('@izi-logistics/common/lib/pages/AccountPage'));
const AccountSettings = React.lazy(() => import('./Settings'));

const Account = ({ store, ...props }) => {
  return (
    <PreloadingComponent>
      <AccountPage
        {...props}
        config={{ client }}
        onlySelectedRoutes={false}
        routes={[
          {
            name: i18n.getResource(i18n.language, KEYS.APP, 'main_filters'),
            path: `/account/filters`,
            url: `/account/filters`,
            component: (p: any) => (
              <PreloadingComponent>
                <AccountSettings {...p} config={{ client }} />
              </PreloadingComponent>
            ),
          },
        ]}
      />
    </PreloadingComponent>
  );
};
export default inject('store')(Account);
