import React from 'react';
import PreloadingComponent from '@izi-logistics/common/lib/components/PreloadingComponent';

const SetPasswordPageC = React.lazy(() => import('@izi-logistics/common/lib/pages/SetPasswordPage'));

const SetPasswordPage = (() => (
  <PreloadingComponent>
    <SetPasswordPageC  />
  </PreloadingComponent>
));
export default SetPasswordPage;
